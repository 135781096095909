<template>
	<div class="modal fade" id="stage-modal" tabindex="-1" role="dialog" aria-labelledby="stageModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen" role="document">
			<div class="modal-content" v-if="!closed">
				<div class="modal-body">
					<div id="stage-content-wrapper">
						<img id="stage-content-img" :src="item.img" />
						<div v-if="hasContent()" id="stage-content-text">
							<p v-if="item.client" class="m-0 text-uppercase">Client: {{ item.client }}</p>
							<p v-if="item.date" id="date" class="m-0">{{ item.date }}</p>
						</div>
						<i id="prev" class="bi bi-caret-left-fill stage-nav-arrow" title="Previous" v-on:click="onPrev"></i>
						<i id="next" class="bi bi-caret-right-fill stage-nav-arrow" title="Next" v-on:click="onNext"></i>
						<button type="button" data-dismiss="modal" title="Close">
							<i class="bi bi-x-circle"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
#date {
	color: #bbbbbb;
}
button {
	position: absolute;
	top: 0;
	right: -30px;
	background: none;
	color: #fff;
	border: none;
	padding: 0;
	font-size: 1.5rem;
}
</style>

<script>
export default {
	name: 'Stage',
	components: {},
	computed: {},
	mounted() {
		this.$root.$on('openStage', (data) => {
			this.openStage(data);
		});
	},
	data() {
		return {
			closed: true,
			item: null,
		};
	},
	props: ['items'],
	methods: {
		hasContent: function () {
			return this.item.client || this.item.date;
		},
		openStage: function (data) {
			this.item = data;
			document.getElementById('stage-trigger').click();
			this.closed = false;
		},
		close: function () {
			this.isActive = false;
			this.$root.$emit('stageClosed');
		},
		onNext: function () {
			let nextIndex = this.getCurrentIndex() + 1;
			if (nextIndex > this.getLastIndex()) {
				nextIndex = this.getFirstIndex();
			}

			this.item = this.items[nextIndex];
		},
		onPrev: function () {
			let prevIndex = this.getCurrentIndex() - 1;
			if (prevIndex < this.getFirstIndex()) {
				prevIndex = this.getLastIndex();
			}

			this.item = this.items[prevIndex];
		},
		getCurrentIndex() {
			return this.items.indexOf(this.item);
		},
		getLastIndex() {
			return this.items.indexOf(this.items[this.items.length - 1]);
		},
		getFirstIndex() {
			return this.items.indexOf(this.items[0]);
		},
	},
};
</script>
