<template>
	<ValidationObserver ref="form" @reset.prevent="reset" v-slot="{}">
		<form id="contact" @submit.prevent="onSubmit">
			<ValidationProvider name="E-mail" rules="required|email" v-slot="{ errors }" tag="div" class="form-group">
				<label for="email">E-Mail</label>
				<input v-model="formData.email" id="email" type="email" class="form-control" required />
				<div class="form-text text-danger" v-if="errors[0]">{{ errors[0] }}</div>
			</ValidationProvider>

			<ValidationProvider name="Name" rules="required" v-slot="{ errors }" tag="div" class="form-group">
				<label for="name">Name</label>
				<input v-model="formData.name" id="name" type="text" class="form-control" required />
				<div class="form-text text-danger" v-if="errors[0]">{{ errors[0] }}</div>
			</ValidationProvider>

			<ValidationProvider name="Message" rules="required" v-slot="{ errors }" tag="div" class="form-group">
				<label for="message">Message</label>
				<textarea v-model="formData.message" id="message" class="form-control" rows="10" required></textarea>
				<div class="form-text text-danger" v-if="errors[0]">{{ errors[0] }}</div>
			</ValidationProvider>

			<div v-if="showSuccess || showErrors" class="mb-3">
				<div class="form-text text-success" v-if="showSuccess">Thank you for submitting your message.</div>
				<div class="form-text text-danger" v-if="showErrors">{{ errors }}</div>
			</div>

			<div class="form-group">
				<VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"></VueRecaptcha>
			</div>

			<button type="submit" class="btn btn-primary btn-lg">Submit</button>
		</form>
	</ValidationObserver>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import axios from 'axios';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import { configure } from 'vee-validate';

Object.keys(rules).forEach((rule) => {
	extend(rule, rules[rule]);
});

localize('en', en);

configure({
	classes: true,
});

export default {
	name: 'ContactForm',
	components: {
		ValidationObserver,
		ValidationProvider,
		VueRecaptcha,
	},
	mounted() {
		this.form = this.$refs.form;
	},
	setup() {
		const siteKey = '6Ld0PrskAAAAAKo8cahLCrxp4Ra66YP9v3-V4Rgi';

		return {
			siteKey,
		};
	},
	data() {
		return {
			form: null,
			showSuccess: false,
			showErrors: false,
			errors: null,
			formData: {},
		};
	},
	methods: {
		onSubmit: function (event) {
			this.showErrors = false;
			this.showSuccess = false;

			this.form.validate().then((success) => {
				if (!success) {
					return;
				}

				axios({
					method: 'post',
					url: `${window.location.origin}/scripts/mail.php`,
					data: this.formData,
				})
					.then((response) => {
						this.showSuccess = true;
						this.resetForm();
					})
					.catch((error) => {
						this.showErrors = true;
						this.errors = error;
						console.error(error);
					});
			});
		},
		resetForm() {
			this.form.reset();
			this.formData = {};
		},

		handleError() {
			console.log('some error');
		},

		handleSuccess(response) {
			this.formData.token = response;
		},
	},
};
</script>
