import Vue from 'vue';
import ITEMS from './../../public/assets/data/data.json';

Vue.component('card', require('./components/Card.vue').default);
Vue.component('stage', require('./components/Stage.vue').default);
Vue.component('mobile-menu', require('./components/MobileMenu.vue').default);
Vue.component('mobile-menu-icon', require('./components/MobileMenuIcon.vue').default);
Vue.component('contact-form', require('./components/ContactForm.vue').default);

const ItemType = Object.freeze({
    KeyArt: 'Key Art',
    PromotionalArt: 'Promotional Art',
    TributeArt: 'Tribute Art',
});

new Vue({
    el: '#app',
    computed: {
        randomItems() {
            return this.items.sort(function () {
                return 0.5 - Math.random();
            });
        },
        promotionalItems() {
            this.items = this.items
                .filter((item) => {
                    return item.type === ItemType.PromotionalArt;
                })
                .sort(function (a, b) {
                    return a.date < b.date ? 1 : -1;
                });

            let numberOfDeletedElm = 1;
            const fullIndex = this.items.findIndex((item) => item.full === true)
            const elm = this.items.splice(fullIndex, numberOfDeletedElm)[0];
            numberOfDeletedElm = 0;
            this.items.splice(0, numberOfDeletedElm, elm);
            return this.items;
        },
        keyItems() {
            return this.items
                .filter((item) => {
                    return item.type === ItemType.KeyArt;
                })
                .sort(function (a, b) {
                    return a.date < b.date ? 1 : -1;
                });
        },
        tributeItems() {
            return this.items
                .filter((item) => {
                    return item.type === ItemType.TributeArt;
                }).sort(function (a, b) {
                    return a.date < b.date ? 1 : -1;
                });
        },
    },
    data: {
        items: ITEMS,
    },
});
