<template>
	<div v-if="isActive" :class="[item.full ? 'grid-item grid-item--full' : 'grid-item']" v-on:click="openStage">
		<img :src="item.img" loading="lazy" />
	</div>
</template>

<style scoped>
.grid-item:hover {
	cursor: pointer;
}
</style>

<script>
export default {
	name: 'Card',
	components: {},
	computed: {
		isActive() {
			if (this.page === 'root' && this.item.hideOnRoot) {
				return false;
			}

			return true;
		},
	},
	mounted() {},
	props: ['item', 'page'],
	data() {
		return {};
	},
	methods: {
		openStage() {
			this.$root.$emit('openStage', this.item);
		},
	},
};
</script>
